<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#13736f"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout v-if="list.role === 'Trader'" wrap pt-5 justify-center>
          <v-flex xs12 pa-4>
            <v-card>
              <v-layout wrap justify-center fill-height>
                <v-flex xs11 align-self-center>
                  <v-layout wrap>
                    <v-flex xs4>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >NAME</v-flex
                        >
                        <v-flex xs12 class="fontpop2">{{list.traderId.name  }}
</v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex class="popfont" xs12 align-self-center
                          >ROLE</v-flex
                        >
                        <v-flex xs12 class="fontpop2">
                          {{ list.role }}
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs4>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >PHONE</v-flex
                        >
                        <v-flex xs12 v-if="list.phone" class="fontpop2">{{
                          list.phone
                        }}</v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs11 align-self-center>
                  <v-layout wrap>
                    <v-flex xs4>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >EMAIL</v-flex
                        >
                        <v-flex xs12 class="fontpop2">{{
                          list.traderId.email
                        }}</v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >ADDRESS</v-flex
                        >
                        <v-flex xs12 class="fontpop2">{{
                          list.traderId.address
                        }}</v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs4>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >GST NUMBER</v-flex
                        >
                        <v-flex xs12 v-if="list">
                          <span v-if="list.traderId">
                            <span
                              v-if="list.traderId.gstNumber"
                              class="fontpop2"
                            >
                              {{ list.traderId.gstNumber }}
                            </span>
                            <span> </span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs11 align-self-center>
                  <v-layout wrap>
                    <v-flex xs3>
                      <v-layout wrap pt-6 pb-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >COMPANY NAME</v-flex
                        >
                        <v-flex xs12 class="fontpop2">{{
                          list.traderId.companyName
                        }}</v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex text-right xs9 v-if="list.status === 'Pending'">
                      <v-layout wrap justify-end>
                        <v-flex xs8 pb-6 class="mainfont">
                          <v-btn
                            color="#29807C"
                            width="40%"
                            height="130%"
                            class="no-uppercase no-shadow"
                            @click="approve = true"
                          >
                            <span style="color: white; font-size: 14px"
                              >Approve</span
                            ></v-btn
                          >
                        </v-flex>
                        <v-flex xs8 pb-10>
                          <v-btn
                            @click="reject = true"
                            color="#F2F2F2"
                            width="40%"
                            height="130%"
                            class="no-uppercase no-shadow mainfont"
                            ><span
                              class="mainfont"
                              style="color: black; font-size: 14px"
                              >Reject</span
                            ></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout v-else-if="list.role === 'Farmer'" wrap pt-5 justify-center>
          <v-flex xs12 pa-4>
            <v-card>
              <v-layout wrap justify-center fill-height>
                <v-flex xs11 align-self-center>
                  <v-layout wrap>
                    <v-flex xs4>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >NAME</v-flex
                        >
                        <v-flex xs12 class="fontpop2">{{list.farmerId.name  }}</v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex class="popfont" xs12 align-self-center
                          >ROLE</v-flex
                        >
                        <v-flex xs12 class="fontpop2">
                          {{ list.role }}
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs3>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >PHONE</v-flex
                        >
                        <v-flex xs12 v-if="list.phone" class="fontpop2">{{
                          list.phone
                        }}</v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs11 align-self-center>
                  <v-layout wrap>
                    <v-flex xs4>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >EMAIL</v-flex
                        >
                        <v-flex xs12 class="fontpop2">{{
                          list.farmerId.email
                        }}</v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >ADDRESS</v-flex
                        >
                        <v-flex xs8 class="fontpop2">{{
                          list.farmerId.address
                        }}</v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs3>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >PAN</v-flex
                        >
                        <v-flex xs12 v-if="list">
                          <span v-if="list.farmerId">
                            <span
                              v-if="list.farmerId.panNumber"
                              class="fontpop2"
                            >
                              {{ list.farmerId.panNumber }}
                            </span>
                            <span class="fontpop2" v-else> Nil </span>
                            <span> </span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs11 align-self-center>
                  <v-layout wrap>
                    <v-flex xs4>
                      <v-layout wrap pt-6 pb-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >AADHAR</v-flex
                        >
                        <v-flex v-if="list" xs12 class="fontpop2">
                          <span v-if="list.farmerId.aadhaarNumber">
                            {{ list.farmerId.aadhaarNumber }}
                          </span>

                          <span class="fontpop2" v-else> Nil </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs4>
                      <v-layout wrap pt-6 pb-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >PLANTATION</v-flex
                        >
                        <v-flex xs12 class="fontpop2">{{
                          list.farmerId.plantation
                        }}</v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4 text-right v-if="list.status === 'Pending'">
                      <v-layout wrap justify-end>
                        <v-flex xs10 pb-6 class="mainfont">
                          <v-btn
                            color="#29807C"
                            width="50%"
                            height="130%"
                            class="no-uppercase no-shadow"
                            @click="approve = true"
                          >
                            <span style="color: white; font-size: 14px"
                              >Approve</span
                            ></v-btn
                          >
                        </v-flex>
                        <v-flex xs10 pb-6>
                          <v-btn
                            @click="reject = true"
                            color="#F2F2F2"
                            width="50%"
                            height="130%"
                            class="no-uppercase no-shadow"
                            ><span
                              class="mainfont"
                              style="color: black; font-size: 14px"
                              >Reject</span
                            ></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout v-else wrap pt-5 justify-center>
          <v-flex xs12 pa-4>
            <v-card>
              <v-layout wrap justify-center fill-height>
                <v-flex xs11 align-self-center>
                  <v-layout wrap>
                    <v-flex xs4>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >NAME</v-flex
                        >
                        <v-flex xs12  v-if="list.customerId && list.customerId.name" class="fontpop2">{{ list.customerId.name }}</v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex class="popfont" xs12 align-self-center
                          >ROLE</v-flex
                        >
                        <v-flex xs12 class="fontpop2">
                          {{ list.role }}
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs3>
                      <v-layout wrap pt-6 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >PHONE</v-flex
                        >
                        <v-flex xs12 v-if="list.phone" class="fontpop2">{{
                          list.phone
                        }}</v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs11 align-self-center>
                  <v-layout wrap>
                    <v-flex xs4>
                      <v-layout wrap pt-6 pb-4 fill-height>
                        <v-flex xs12 align-self-center class="popfont"
                          >EMAIL</v-flex
                        >
                        <v-flex xs12  v-if="list.customerId && list.customerId.email" class="fontpop2">{{ list.customerId.email }}</v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="approve"
      max-width="600px"
    >
      <v-card >
        <v-layout wrap>
          <v-flex xs12>
            <v-card-title
              class="mainfont"
              style="color: black; font-size: 18px; font-weight: lighter"
            >
              Are you sure you want to Approve?
            </v-card-title>
          </v-flex>
        </v-layout>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="approve = false" class="mainfont"
            >Cancel</v-btn
          >
          <v-btn color="red" class="mainfont" text @click="userAction('Active')"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="reject"
      max-width="600px"
    >
      <v-card class="cus2">
        <v-layout wrap justify-center>
          <v-flex xs8>
            <v-card-title
              class="mainfont"
              style="color: white; font-size: 18px; font-weight: lighter"
            >
              Are you sure you want to Reject?
            </v-card-title>
            <v-flex xs10>
              <v-text-field
                class="mainfont"
                dense
                v-model="remarks"
                outlined
                label="Remarks"
                required
              ></v-text-field>
            </v-flex>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="reject = false" class="mainfont"
            >Cancel</v-btn
          >
          <v-btn
            color="red"
            class="mainfont"
            text
            @click="userAction('Rejected')"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  beforeRouteLeave(to, from, next) {
  // Set exe to 1 in localStorage
  localStorage.setItem('exe', 1);

  // Log "hi" along with the value of exe

  // Continue with the navigation
  next();
},


  data() {
    return {
      showSnackBar: false,
      msg: null,
      appLoading: false,
      id: this.$route.query.id,
      reject: null,
      list: {},
      approve: false,
      dialog2: false,
      remarks: null,
    };
  },
  mounted() {
    this.getList();
  },
  created() {
  this.currentPage3 = parseInt(this.$route.query.currentPage3);
},

  methods: {
    getList() {
      this.appLoading = true;
      axios
        .get("/admin/user/view/" + this.id, {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            page: this.currentPage,
          },
        })
        .then((response) => {
          this.list = response.data.data;
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    userAction(status) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/admin/user/approve/reject",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          status: status,
          id: this.id,
          remarks: this.remarks,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$router.push("/ActiveUsersPage");
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
<style>
.no-uppercase {
  text-transform: none !important;
}
.cus2 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.no-shadow {
  box-shadow: none;
}
</style>
